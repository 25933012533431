<template>
	<div class="bgpage" style="padding-top: 50px; min-height: 100vh;">

		<div id="app-container" style="margin: 100px auto; width: 500px; border-radius: 20px; padding: 50px;">
			<div id="logo-holder" style="text-align: center; padding: 20px 0;">
				<img src="/public-static/logos/ace-digital-logo.png" alt="logo" style="" />
			</div>

			<div>
				<div id="form-wrapper" >
					<div class="login-form">
						<input type="text" v-model="email" placeholder="Email address" />
						<input type="password" v-model="pass" placeholder="Password" />
					</div>
					<div>
						<button class="loginbtn" v-on:click="doLoginFormSubmit">Submit</button>
					</div>
				</div>
			</div>

		</div>

	</div>
</template>

<script>
import axios from "axios";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: 'LoginPage',
	components: {

	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,

			validLoginForm: true,
			loginFormWorking: false,

			email: '',
			pass: '',
		}
	},
	computed: {

	},
	methods: {
		doLoginFormSubmit() {
			if(this.loginFormWorking === false) { //only proceed if not already waiting on callback
				setTimeout(function() {
					this.loginFormWorking = true
					let form = {
						email: this.email,
						pass: this.pass,
					}
					let self = this
					axios.post(
						"post/do-login.php",
						JSON.stringify({data: form, redir: self.requestedLink})
					).then(function(response) {
						var ret = response.data; //response.data is returned info
						if(ret.error === 1) {
							self.loginFormWorking = false
							self.showKalert(ret)
						}
						else if(ret.success === 1) {
							self.$router.push("/")
						}
					}).catch(function(error) {
						self.loginFormWorking = false
						console.log(error);
					});
				}.bind(this),500);
			}
		},
	},
	watch: {

	},
	props: {

	},
	mounted() {
		document.title = "ACE Digital";
		// if(this.store.sesscheckcomplete && this.store.user.id > 0) {
		// 	this.$router.push("/");
		// }
	},
	created() {

	},
}
</script>

<style scoped>
#logo-holder img {
	width: 300px;
	margin: 0 auto;
}
#form-wrapper {
	width: 300px;
	margin: 0 auto;
}
.login-form input {
	margin-bottom: 20px;
	width: 100%;
	padding: 10px;
	font-size: 16px;
	border: 1px solid #DDD;
}
.loginbtn {
	background-color: var(--bluedark);
	color: #FFF;
	padding: 15px 40px;
	border-radius: 25px;
	border: none;
	cursor: pointer;
	transition: all 0.3s linear;
	font-weight: 600;
	font-size: 14px;
	margin: 0 2px;
}
.loginbtn:hover {
	background-color: var(--bluedarkhover);
}

</style>
