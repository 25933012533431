<template>
	<div class="sites-list">

		<BlanketOverlayWithPopup v-if="selectedPopupSite" :title="selectedPopupSite.label"
			@close="selectedPopupSite = null" :nobuttons="true" width="1000px">
			<template v-slot:main>
				<div class="site-popup-url">
					<p class="liner" style="display: block; width: 100%;">
						<a :href="selectedPopupSite.rootUrl" target="_blank">{{selectedPopupSite.rootUrl}}</a>
					</p>
				</div>
				<div class="site-popup-content">
					<div class="left-side">
						<div v-if="selectedPopupSite.cms.length > 0" class="cms-main">
							<span class="cms-icon" v-html="selectedPopupSite.cms_icon"></span>
							<span>{{selectedPopupSite.cms_version}}</span>
						</div>
						<p v-if="selectedPopupSite.cms === 'WP'" class="liner"><span class="boldtitle">Theme:</span>{{selectedPopupSite.theme_name}}</p>
						<p class="liner"><span class="boldtitle">Server:</span>{{selectedPopupSite.server_name}}</p>
						<p class="liner"><span class="boldtitle">Server IP:</span>{{selectedPopupSite.serverObject.public_ip}}</p>
						<p class="liner"><span class="boldtitle">Live Site:</span>{{intToYes(selectedPopupSite.isLive)}}</p>
						<p class="liner"><span class="boldtitle">Last Updated:</span>{{selectedPopupSite.lastfetchtimenice}}</p>
						<p class="liner">
							<span class="boldtitle">Cache:</span>
							<span v-if="selectedPopupSite.acecache_installed">Installed</span>
							<span v-if="selectedPopupSite.acecache_active">, Active</span>
						</p>

						<div v-if="selectedPopupSite.analytics && selectedPopupSite.analytics.p7d">
							<p class="liner" style="margin-top: 10px;"><span class="bold">Analytics (past 7 days)</span></p>
							<p>Sessions: {{selectedPopupSite.analytics.p7d.sessions}}</p>
							<p>Uniques: {{selectedPopupSite.analytics.p7d.unique_ips}}</p>
							<p>Page Views: {{selectedPopupSite.analytics.p7d.hits}}</p>
							<p>Cached Views: {{selectedPopupSite.analytics.p7d.cached_pct}}%</p>
							<p>Mobile Views: {{selectedPopupSite.analytics.p7d.mobile_pct}}%</p>
						</div>
					</div>
					<div v-if="selectedPopupSite.cms === 'WP'" class="right-side">
						<p class="bold">Plugins Active:</p>
						<ul class="plugins-ul">
							<li v-for="(p, ind) in selectedPopupSite.pluginObjects" :key="ind">{{p.title}} {{p.version}}</li>
						</ul>
					</div>
				</div>
			</template>
		</BlanketOverlayWithPopup>

		<div class="inner">
			<ALTable :searchbox="true" :rowdata="sitesRowData" tblclasses="noborderv" :pagination="17"
				:searchablefields="['label']" :excel="true" @rowclicked="selectRow($event)"
				:cols="[
					{title: 'Status', fldid:'computed_status'},
					{title: 'Name', fldid:'label', sortable: true, href: 'label_href'},
					{title: 'CMS Ver', fldid:'cms_version', sortable: true},
					{title: 'Plugins', fldid:'plugin_icons'},
					{title: 'Server', fldid:'server_name', sortable: true, filter: true},
					{title: 'Cache', fldid:'acecache_installed', sortable: true, align: 'center', filter: true},
					{title: 'Active', fldid:'acecache_active', sortable: true, align: 'center', filter: true},
					{title: 'Sess P7D', fldid:'p7dSessions', sortable: true, align: 'right', numtype: 'number'},
					{title: 'Views P7D', fldid:'p7dViews', sortable: true, align: 'right', numtype: 'number'},
					{title: 'Pct Cache', fldid:'p7dCachedPct', sortable: true, align: 'right', numtype: 'pct'},
				]"
			>
				<template v-slot:topfilters>
					<div class="top-selector">
						<label class="top-selector-label">
							<input type="checkbox" v-model="cms_wordpress_checked" />
							WordPress
						</label>
						<label class="top-selector-label">
							<input type="checkbox" v-model="cms_processwire_checked" />
							ProcessWire
						</label>
					</div>
					<div class="top-selector">
						<label class="top-selector-label">
							<input type="checkbox" v-model="site_live_checked" />
							Live Sites
						</label>
						<label class="top-selector-label">
							<input type="checkbox" v-model="site_dev_checked" />
							Dev Sites
						</label>
					</div>
					<div>
						<FilterItem label="Plugins" :has_search="true" @searchval="pluginTextSearch = $event">
							<template v-slot:items>
								<div class="filter-item" v-for="item in pluginNamesList" :key="item">
									<label><input v-model="pluginNamesFilterList" type="checkbox" :value="item" />{{item}}</label>
								</div>
							</template>
						</FilterItem>
					</div>
				</template>

			</ALTable>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import ALTable from "@/components/ALTable.vue";
import FilterItem from "@/components/FilterItem.vue";
import BlanketOverlayWithPopup from "@/components/BlanketOverlayWithPopup.vue";

export default {
	name: 'SitesContent',
	components: {
		BlanketOverlayWithPopup,
		FilterItem,
		ALTable

	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,
			hasMounted: false,

			cms_wordpress_checked: true,
			cms_processwire_checked: true,

			site_live_checked: true,
			site_dev_checked: true,
			pluginNamesFilterList: [],
			pluginTextSearch: '',

			selectedPopupSite: null,
		}
	},
	computed: {
		pluginNamesList() {
			let names = []
			for(let p of this.store.plugins) {
				if(this.pluginNamesFilterList.includes(p.title) && !names.includes(p.title)) names.push(p.title)
				else if(p.title.toLowerCase().includes(this.pluginTextSearch.toLowerCase()) && !names.includes(p.title)) names.push(p.title)
			}
			return names
		},
		sitesRowData() {
			if(this.hasMounted === false) return []
			let rows = []
			for(let a of this.store.apps) {
				if(a.deleted === 0) {
					let computedStatus = ''
					if(a.isLive === 1) {
						computedStatus += `<span title="Site is active on live domain." style="margin-right: 5px; display: inline-block; background-color: #00BB00; height: 20px; width: 20px; border-radius:10px;"></span>`
					}
					else {
						computedStatus += `<span title = "Site is still on development domain." style = "margin-right: 5px; display: inline-block; background-color: #FF8888; height: 20px; width: 20px; border-radius:10px;"></span>`
					}
					computedStatus += a.serverTypeIcon + a.cms_icon
					let pluginNames = a.pluginObjects.map((p) => p.title)
					let myrow = {
						ai: a.ai,
						label: a.label,
						label_href: a.rootUrl,
						cms: a.cms,
						cms_version: a.cms_version,
						server_name: a.server_name,
						acecache_installed: this.boolToYes(a.acecache_installed),
						acecache_active: this.boolToYes(a.acecache_active),
						p7dSessions: a.analytics.p7d.sessions,
						p7dViews: a.analytics.p7d.hits,
						p7dCachedPct: a.analytics.p7d.cached_pct,
						computed_status: computedStatus,
						plugin_icons: a.plugin_icons,
						plugin_names: pluginNames,
						isLive: a.isLive,
					}
					rows.push(myrow)
				}
			}
			//handle filters
			if(this.cms_wordpress_checked && !this.cms_processwire_checked) {
				rows = rows.filter((r) => r.cms === 'WP')
			}
			else if(!this.cms_wordpress_checked && this.cms_processwire_checked) {
				rows = rows.filter((r) => r.cms === 'PW')
			}
			if(this.site_live_checked && !this.site_dev_checked) {
				rows = rows.filter((r) => r.isLive === 1)
			}
			else if(!this.site_live_checked && this.site_dev_checked) {
				rows = rows.filter((r) => r.isLive === 0)
			}
			if(this.pluginNamesFilterList.length > 0) {
				rows = rows.filter((r) => {
					for(let p of r.plugin_names) {
						if(this.pluginNamesFilterList.includes(p)) return true
					}
					return false
				})
			}
			return rows
		},
	},
	methods: {
		selectRow(row) {
			this.selectedPopupSite = this.store.apps.find((a) => a.ai === row.ai)
		},
	},
	watch: {},
	props: {

	},
	mounted() {
		this.hasMounted = true
	},
	created() {
		document.title = "Sites - ACE Digital"
	},
}
</script>

<style scoped>
.inner {
	padding-left: 50px;
	padding-right: 50px;
}
.site-popup-url {
	padding: 10px 0 0 0;
}
.site-popup-content {
	display: flex;
	gap: 50px;
	justify-content: space-between;
	padding: 20px 0 50px 0;
}
.site-popup-content .left-side,
.site-popup-content .right-side {
	flex: 1;
	width: 50%;
}
.site-popup-content .cms-main {
	display: flex;
	align-items: center;
	font-size: 24px;
	margin-bottom: 15px;
}
.boldtitle {
	font-weight: bold;
	width: 130px;
	display: inline-block;
}
.bold {
	font-weight: bold;
}
.liner {
	line-height: 1.2;
	margin-bottom: 5px;
}
ul.plugins-ul {
	margin-top: 5px;
	padding-left: 24px;
	list-style-type: disc;
	line-height: 1.5;
}
</style>
